import React, { Component } from 'react'
import Iframe from '../../Iframe/Iframe'
import {KeyboardArrowRight, OpenLinkNewWindowIcon} from '../../icons/index'
import classes from './moreInfo.module.css'


const iconColor = "#4B53BC" // primary color

export default class extends Component {
    state = {
        addressOne: false,
        addressTwo: false,
        horaireConsulation: true,
        horaireBureau: false
    }

    handleAddressOneClick = e => {
        e.preventDefault()
        if(this.state.addressOne) {
            this.setState({addressOne: false})
        } else {
            this.setState({addressOne: true})
        }
    }

    handleAddressTwoClick = e => {
        e.preventDefault()
        if(this.state.addressTwo) {
            this.setState({addressTwo: false})
        } else {
            this.setState({addressTwo: true})
        }
    }

    handleSelectHoraire = (e) => {
        
        console.log('attr: ', e.target)
    }

    render() {
        return (
            <div>
                <div style={{maxWidth: 1170, padding: '40px 20px', margin: 'auto'}}>
                    <div className={classes.MoreInfoWrapper} style={{marginBottom: 40}}>
                        <div className={classes.MoreInfoItem}>
                            <h4 style={{fontWeight: 700, marginBottom: 10, color: '#333'}}>Nos Adresses</h4>
                            <div style={{fontSize: '1rem'}}>
                                <div className={this.state.addressOne && classes.AddressOpen} style={{marginBottom: 8}}>
                                    <div>
                                        <a onClick={this.handleAddressOneClick}
                                           href="/"
                                           style={{fontWeight: 600, display:"flex", alignItems:'center',flexWrap:'wrap'}}
                                        >
                                            {!this.state.addressOne && <KeyboardArrowRight fill={iconColor}/>}Grand-Rue 1A{!this.state.addressOne && <span>, </span>} {!this.state.addressOne && <span style={{padding: "0 4px"}}>2000</span>} {!this.state.addressOne && <span>Neuchâtel</span>}
                                        </a>
                                    </div>
                                    {this.state.addressOne && <div>
                                        <div>1er Étage</div>
                                        <div>2000 Neuchâtel</div>

                                        <div style={{textDecoration:'underline', fontSize:'0.9rem'}}>
                                            <a className={classes.OpenNewMap} target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/PSI+:+Psy-Scan+Institute+S%C3%A0rl/@46.991943,6.928913,16z/data=!4m5!3m4!1s0x0:0xdc7be15bcbfa5718!8m2!3d46.9919431!4d6.9289134?hl=fr-fr">
                                                Ouvrir sur Google Maps
                                                <OpenLinkNewWindowIcon width="14" height="14"/>
                                            </a>
                                        </div>
                                    </div>}
                                </div>
                                <div className={this.state.addressTwo && classes.AddressOpen}>
                                    <div>
                                        <a onClick={this.handleAddressTwoClick}
                                           href="/"
                                           style={{fontWeight: 600, display:"flex", alignItems:'center', flexWrap:'wrap'}}>
                                            {!this.state.addressTwo && <KeyboardArrowRight fill={iconColor}/>}Rue de la Maladière 20{!this.state.addressTwo && <span>, </span>} {!this.state.addressTwo && <span style={{padding:"0 4px"}}>2000</span>} {!this.state.addressTwo && <span>Neuchâtel</span>}
                                        </a>
                                    </div>
                                    {this.state.addressTwo && <div>
                                        <div>Rez-de-Chaussée : Porte 1 </div> 
                                        <div>Code de l’entrée : 2020</div>
                                        <div>2000 Neuchâtel</div>
                                        <div style={{textDecoration:'underline', fontSize:'0.9rem'}}>
                                            <a className={classes.OpenNewMap} target="_blank" rel="noreferrer" href="https://www.google.ch/maps/place/Rue+de+la+Maladi%C3%A8re+20,+2000+Neuch%C3%A2tel,+Suisse/@46.9956254,6.9397186,17z/data=!3m1!4b1!4m5!3m4!1s0x478e0a22e0de327d:0x90f2d159346cf0fe!8m2!3d46.9956254!4d6.9419073?hl=fr-fr">
                                                Ouvrir sur Google Maps
                                                <OpenLinkNewWindowIcon width="14" height="14"/>
                                            </a>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className={classes.MoreInfoItem}>
                            <h4 style={{fontWeight: 700, marginBottom: 10, color: '#333'}}>Contactez-nous</h4>
                            <div style={{fontSize: '1.1rem'}}>
                                <div><a href="tel:+41582010611" style={{fontWeight: 600}}>058 201 06 11</a></div>
                                <div><a href="mailto:admin@psy-scan.ch">admin@psy-scan.ch</a></div>
                            </div>
                        </div>
                        <div className={classes.MoreInfoItem} >
                            <h4 style={{fontWeight: 700, marginBottom: 16, color: '#333'}}>
                                Horaires: <span onClick={() => this.setState({...this.state, horaireConsulation: true, horaireBureau: false})} className={this.state.horaireConsulation ? [classes.Office, classes.Active].join(' ') : classes.Office}>Consultation</span>
                                <span onClick={() => this.setState({...this.state, horaireConsulation: false, horaireBureau: true})} className={this.state.horaireBureau ? [classes.Office, classes.Active].join(' ') : classes.Office}>Secrétariat</span></h4>
                            {this.state.horaireConsulation && <div style={{fontSize: '1.1rem'}}>
                                <div>9h à 12h15 et 13h30 à 18h30 la semaine</div>
                                <div>Fermé le week-end</div>
                            </div> }
                            {this.state.horaireBureau && <div style={{fontSize: '1.1rem'}}>
                                <div>13h à 17h la semaine</div>
                                <div>Fermé le week-end</div>
                            </div> }

                        </div>
                    </div>

                    <div className={classes.MoreInfoItemBody}>
                        <Iframe width="100%" height="340px" src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyD5d58sVD8JymoPG0mWxmQP8glK9_m9cI4&q=Psi+:+Psy-Scan+Institute+Sàrl'}/>
                    </div>
                </div>

            </div>
        )
    }
}

